/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal, window, document) {

  'use strict';

  // To understand behaviors, see https://drupal.org/node/756722#behaviors
  Drupal.behaviors.my_custom_behavior = {
    attach: function (context, settings) {

      // Place your code here.

    }
  };
  
  
  var bp = {
      xsmall: 480,
      small: 600,
      medium: 771,
      large: 980,
      xlarge: 1200,
      xxlarge: 1400,
      menuMobile: 980
  };
  
  $(document).ready(function() {

    // Document
    var w = $(window);
    var d = $(document);
    var body = $('body');
    
    
    // ==============================================
    // HEADER - MENU DESKTOP
    // ==============================================
    
    var mainMenu = $('.region-navigation .block-menu-block');
    
    var mainMenuAnimationSpeed = 0.2;
    var mainMenuItems = mainMenu.find('>.inner >.menu-block-wrapper >.menu >.menu__item');
    var mainMenuItemsMenus = mainMenuItems.find('.menu');

    var closeMainMenu = function() {
        mainMenuItemsMenus.removeClass('active');
        mainMenuItems.removeClass('opened');
    };

    var handleMainMenuItem = function(e, menuItem) {
        if (menuItem.hasClass('is-expanded')) { // has submenu

            e.preventDefault(); // prevent default action

            if (!menuItem.hasClass('opened')) { // not active
                var menuItemMenu = menuItem.find('.menu');

                mainMenuItemsMenus.removeClass('active');
                menuItemMenu.addClass('active');

                mainMenuItems.removeClass('opened');
                menuItem.addClass('opened');
            } else { // active link
                closeMainMenu();
            }
        } else { // has no submenu
            closeMainMenu();
        }
    };
    /*
    mainMenuItems.find('> .menu__link').on('click', function(e) {

        var menuItem = $(this).parent();
        
        if (Modernizr.touchevents) {
            handleMainMenuItem(e, menuItem);
        }
    });*/
    
    var handleClickMenuItems = function(){
      mainMenuItems
        .find('> .menu__link')
          .off('click.mainMenuClick')
          .on('click.mainMenuClick', function(e){
            var menuItem = $(this).parent();
            if (menuItem.hasClass('is-expanded')) {
              // has a submenu
              if (!menuItem.hasClass('opened')) {
                // not opened => block click
                e.preventDefault();
                
                var menuItemMenu = menuItem.find('.menu');
                
                mainMenuItemsMenus.removeClass('active');
                menuItemMenu.addClass('active');
                
                mainMenuItems.removeClass('opened');
                menuItem.addClass('opened');
              } else {
                if ($(this).hasClass('nolink')) {
                  // close
                  closeMainMenu();
                } else {
                  // go to link target
                }
              }
            }
          });
    };
    
    enquire.register('(min-width: ' + bp.menuMobile + 'px)', {
      match: function() {
        if (Modernizr.touchevents) {
          handleClickMenuItems();
        } else {
          // on desktop, remove opening menus on click and add mouse over
          mainMenuItems
            .on({
              'mouseenter.mainMenuHover': function(e) {
                handleMainMenuItem(e, $(this));
              },
              'mouseleave.mainMenuHover': function() {
                closeMainMenu();
              }
            });
        }
      },
      unmatch: function() {
        mainMenuItems
          .off('.mainMenuHover')
          .find('> .menu__link')
            .off('click.mainMenuClick');
      }
    });
    
    $(document).on('click.closeMenu touchend.closeMenu', function(e) {
        if (!mainMenu.is(e.target) && // if the target of the click isn't the container...
            mainMenu.has(e.target).length === 0) // ... nor a descendant of the container
        {
            // Close menu
            closeMainMenu();
        }
    });
    
    
    // ==============================================
    // HEADER - MENU MOBILE
    // ==============================================
    
    // Open/Close menu mobile
    
    $('#mobile-menu-link').click(function(e){
        e.preventDefault();
        body.toggleClass('menu-mobile-active');
    });
    
    var menuMobileWrapBlocks,
        menuMobileWrapDefault,
        blockMenuNews,
        menuMobileOverlay;
    
    enquire.register('(max-width: ' + (bp.menuMobile - 1) + 'px)', {
        setup: function () {
            
            // Initialize variables
            menuMobileWrapBlocks  = $('.region-navigation > .wrap');
            menuMobileWrapDefault = $('.region-header > .wrap');
            blockMenuNews    = $('.block-menu-block.menu-news');
        },
        match: function () {
            
            // Move blocks into mobile menu
            menuMobileWrapBlocks
              .append(blockMenuNews);
            
            // Add links to open submenus
            mainMenuItems.each(function(){
              var _this = $(this);
              if (_this.find('> .menu').length > 0) {
                var link = _this.find('> .menu__link');
                var linkSubmenu = $('<a href="#" class="menu__link-submenu"></a>');
                linkSubmenu
                  .on('click', function(e){
                    e.preventDefault();
                    $(this)
                      .toggleClass('active')
                      .next('.menu')
                        .slideToggle();
                  })
                  .insertAfter(link);
                // If the menu link has no link (!) it open the submenu too
                if (link.hasClass('nolink')) {
                  link.on('click.menuMobile', function(e){
                    $(this)
                      .next('a') // .menu__link-submenu
                        .toggleClass('active')
                        .next('.menu')
                          .slideToggle();
                  });
                }
                // Reset display of submenu
                _this.find('> .menu').hide();
              }
            });
            
            // Create overlay on top of the content, with listener on it to close the menu if clicked
            if (menuMobileOverlay === undefined) {
              menuMobileOverlay = $('<div id="menu-mobile-overlay">')
                .appendTo($('#page'))
                .on('click', function(){
                  body.removeClass('menu-mobile-active');
                });
            }
        },
        unmatch: function () {
            
            // Close menu mobile
            body.removeClass('menu-mobile-active');
            
            // Remove links to open submenus
            mainMenuItems
              .find('.menu__link-submenu')
                .remove()
                .end()
              .find('.menu__link')
                .off('.menuMobile');
            
            // Move blocks back from mobile menu
            menuMobileWrapDefault
              .append(blockMenuNews);
        },
        deferSetup: true
    });
    
    
    $('#mobile-search-link').on('click', function(e){
      e.preventDefault();
      $('.block-amphenol-search').slideToggle();
    });
    
    
    // ==============================================
    // HEADER - MENU LANGUAGE
    // ==============================================
    
    var menuLanguageId = 'block-locale-language-content';
    var menuLanguage = $('.language-switcher-locale-url');
    if (menuLanguage.length) {
        // Open menu language
        var openMenuLanguage = function(){
            if (typeof TweenLite != 'undefined') {
                TweenLite.to(menuLanguage, 0.3, {
                    className: '+=active',
                    ease: Power1.easeInOut
                });
            } else {
                menuLanguage.addClass('active');
            }
        };
        // Close menu language
        var closeMenuLanguage = function(){
            if (typeof TweenLite != 'undefined') {
                TweenLite.to(menuLanguage, 0.3, {
                    className: '-=active',
                    ease: Power1.easeInOut
                });
            } else {
                menuLanguage.removeClass('active');
            }
        };
        // Click on current language link
        menuLanguage.find('a.active').click(function(e){
            e.preventDefault();
            if (!menuLanguage.hasClass('active')) {
                openMenuLanguage();
            } else {
                closeMenuLanguage();
            }
        });
        // Click on document
        $(document).on('click touchend', function(e) {
            if ($(e.target).attr('id') != menuLanguageId && // if the target of the click isn't the container...
                menuLanguage.has(e.target).length === 0) // ... nor a descendant of the container
            {
                closeMenuLanguage();
            }
        });
    }
    
    // ==============================================
    // HEADER - MENU SUPPORT
    // ==============================================
    
    var menuSupport = $('.menu-support');
    var menuSupportFirstLink = menuSupport.find('.menu__link').eq(0);
    
    var closeMenuSupport = function(){
      var subMenu = menuSupportFirstLink.next('.menu');
      if (typeof TweenLite != 'undefined') {
        TweenLite.to(subMenu, 0.3, {
          className: '-=active',
          ease: Power1.easeInOut
        });
      } else {
          subMenu.removeClass('active');
          // Remove listener on document
          $(document).off('.menuSupport');
      }
    };
    
    menuSupportFirstLink.on('click', function(e){
      e.preventDefault();
      var subMenu = $(this).next('.menu');
      if (!subMenu.hasClass('active')) {
        subMenu.addClass('active');
        // Listen to click/touch outside the menu to close it
        $(document)
          .off('.menuSupport')
          .on('click.menuSupport touchend.menuSupport', function(e) {
            if ($(e.target).attr('id') != menuSupport.attr('id') && menuSupport.has(e.target).length === 0){
              closeMenuSupport();
            }
          });
      } else {
        closeMenuSupport();
      }
    });
    
    
    
    // ==============================================
    // COMMONS
    // ==============================================
    
    // Fitvids (responsive embedded videos)
    $(".embedded-video").fitVids();
    
    // Fitvids (responsive embedded videos) for iframe in wysiwyg
    if ($('#page-content').find('iframe').length > 0) {
      var iframe = $('#page-content').find('iframe');
      var iframeParent = iframe.parent('p');
      if (iframeParent.length > 0) {
        iframeParent
          .css({
            maxWidth: iframe.attr('width'),
            margin: '1.5em auto'
          })
          .fitVids();
      }
    }
    
    
    // ==============================================
    // RESPONSIVE TABLES
    // ==============================================
    
    // @TODO : enquireJS, matchheight
    var switched = false;
    var updateTables = function() {
      if ((w.width() < bp.xlarge) && !switched){
        switched = true;
        $('table.responsive').each(function(i, element) {
          splitTable($(element));
        });
        return true;
      }
      else if (switched && (w.width() >= bp.xlarge)) {
        switched = false;
        $('table.responsive').each(function(i, element) {
          unsplitTable($(element));
        });
      }
    };
    
    w
      .load(updateTables)
      .on('redraw',function(){ // An event to listen for
        switched = false;
        updateTables();
      })
      .on('resize', updateTables);
    
    function splitTable(original) {
      original.wrap('<div class="table-wrapper" />');
      
      var copy = original.clone();
      copy.find('td:not(:first-child), th:not(:first-child)').css('display', 'none');
      copy.removeClass('responsive');
      
      original.closest('.table-wrapper').append(copy);
      copy.wrap('<div class="pinned" />');
      original.wrap('<div class="scrollable" />');

      //setCellHeights(original, copy);
    }
  
    function unsplitTable(original) {
      original
        .closest('.table-wrapper')
          .find('.pinned')
            .remove()
            .end()
          .end()
        .unwrap()
        .unwrap();
    }

    function setCellHeights(original, copy) {
      var tr = original.find('tr'),
          tr_copy = copy.find('tr'),
          heights = [];

      tr.each(function (index) {
        var self = $(this),
            tx = self.find('th, td');

        tx.each(function () {
          var height = $(this).outerHeight(true);
          heights[index] = heights[index] || 0;
          if (height > heights[index]) heights[index] = height;
        });
      });

      tr_copy.each(function (index) {
        $(this).height(heights[index]);
      });
    }
    // #END RESPONSIBLE TABLE
    
    
    
    // ==============================================
    // HOMEPAGE
    // ==============================================
    
    if ( body.hasClass('front') ) {
      
      /*** BLOCK SLIDER ***/
      
      if ($('.view-amphenol-home-page-slider').length > 0) {
        // Slider
        var homeSlider = $('.view-amphenol-home-page-slider').find('.view-content');
        var homeSliderParams = {
          items: 1,
          margin: 0,
          loop: true,
          nav: false,
          dots: true,
          autoHeight: true,
          responsive: {
            771: {
              nav: true
            }
          }
        };
        //handleOwlCarousel(homeSlider, homeSliderParams);
        
        w.load(function(){ // because bug on iPhone
          handleOwlCarousel(homeSlider, homeSliderParams);
        });
      }
      
      
      /*** MENU QUICK ACCESS ***/
      
      var menuQuickAccess = $('#block-menu-menu-quick-access');
      if (menuQuickAccess.length > 0) {
        enquire.register('(max-width: ' + (bp.medium - 1) + 'px)', {
            match: function() {
              var menuQuickAccessToggle = $('<a href="#" class="menu-quick-access-toggle">'+ Drupal.t('Select a page...', [], {context: "amphenol"}) +'</a>')
                .on('click', function(e){
                  e.preventDefault();
                  $(this).next('.menu').slideToggle(200);
                })
                .insertBefore(menuQuickAccess.find('.menu'));
              menuQuickAccess
                .find('.menu')
                  .hide();
            },
            unmatch: function() {
              menuQuickAccess
                .find('.menu')
                  .show()
                  .end()
                .find('.menu-quick-access-toggle')
                  .remove();
            }
        }, true);
      }
      
      
      /*** SECTION EVENTS ***/
      
      if ($('.view-amphenol-news-events').length > 0) {
        // Slider
        var homeEventsSlider = $('.view-amphenol-news-events').find('.view-content');
        var homeEventsSliderParams = {
          items: 1,
          margin: 8,
          center: true,
          stagePadding: 25,
          loop: true,
          nav: false,
          dots: false,
          responsive: {
            0: {
              items: 1
            },
            480: {
              items: 2,
              center: false
            }
          },
          onResized: function(event) {
            $.fn.matchHeight._update();
          }
        };
        
        enquire.register('(max-width: ' + (bp.medium - 1) + 'px)', {
            match: function() {
              handleOwlCarousel(homeEventsSlider, homeEventsSliderParams);
            }
        }, true).register('(min-width: ' + bp.medium + 'px)', {
            match: function() {
              destroyOwlCarousel(homeEventsSlider);
            }
        }, true);
      }
      
      
      /*** SECTION MARKETS ***/
      
      if ($('.view-amphenol-home-markets').length > 0) {
        // Slider
        var homeMarketsSlider = $('.view-amphenol-home-markets').find('.view-content');
        var homeMarketsSliderParams = {
          items: 1,
          loop: true,
          nav: false,
          dots: false,
          responsive: {
            0: {
              nav: false
            },
            480: {
              nav: true
            }
          }
        };
        
        handleOwlCarousel(homeMarketsSlider, homeMarketsSliderParams);
      }
      
      
      /*** SECTION TWITTER ***/
      
      var blockTwitter = $('.block-twitter-block');
      if (blockTwitter.length > 0) {
        // Hide media in tweets and change some style
        var hideTweetMedia = function() {
          blockTwitter
            .find('iframe')
              .contents()
                .find('.timeline-Tweet')
                  .css('background-color', 'transparent')
                  .end()
                .find('.timeline-Tweet-retweetCredit')
                  .hide()
                  .end()
                .find('.timeline-Tweet-text')
                  .css({
                    'font-size': '14px',
                    'line-height': '20px'
                  })
                  .end()
                .find('.timeline-Tweet-media')
                  .hide()
                  .end()
                .find('.timeline-Tweet-metadata')
                  .hide()
                  .end()
                .find('.timeline-Tweet-actions')
                  .hide();
          /*      
          blockTwitter
            .css('height', '100%')
            .find('iframe')
              .contents()
                .find('.timeline-TweetList')
                  .bind('DOMSubtreeModified propertychange', function() {
                    hideTweetMedia(this);
                  });*/
        };
        blockTwitter.delegate('#twitter-widget-0','DOMSubtreeModified propertychange', function() {
          hideTweetMedia();
        });
      }
    }
    
    
    
    // ==============================================
    // LISTING GAMMES
    // ==============================================
    
    if ( $('.view-gamme-grid').length > 0 ) {
      // Uniform height
      $('.view-mode-term_teaser')
        .find('.term-title')
          .matchHeight()
          .end()
        .find('.term-header')
          .matchHeight();
      
      if ($('.amphenol-custom-inquiry').length > 0) {
        $('.view-mode-term_teaser, .amphenol-custom-inquiry .inner').matchHeight({
          property: 'min-height'
        });
      }
    }
    
    
    if (body.hasClass('page-taxonomy-products') && $('.pane-facetapi').length > 0) {
      enquire.register('(max-width: ' + (bp.small - 1) + 'px)', {
        match: function() {
          $('.pane-facetapi .pane-title')
            .next('.item-list')
              .hide()
              .end()
            .on('click.facetApi', function(){
              $(this)
                .toggleClass('active')
                .next('.item-list')
                  .toggle();
          });
          if ($('.pane-facetapi .pane-title').next('.item-list').find('.deactivate').length > 0) {
            $('.pane-facetapi .pane-title').trigger('click');
          }
        },
        unmatch: function() {
          $('.pane-facetapi .pane-title')
            .off('.facetApi')
            .removeClass('active')
            .next('.item-list')
              .show();
        }
      });
    }
    
    
    
    // ==============================================
    // NODE GAMME FULL
    // ==============================================
    
    // View serie > Uniform height
    if ( $('.view-serie').length > 0 || $('.field-name-field-series').length > 0 ) {
      matchHeightNodeSerieTeaser();
      /*$('.node-serie.node-teaser')
        .find('.field-name-title-field')
          .matchHeight()
          .end()
        .find('.field-name-body')
          .matchHeight();*/
    }
    
    
    
    // ==============================================
    // NODE SERIE FULL
    // ==============================================
    
    if (body.hasClass('node-type-serie')) {
      
      // Uniform height
      $('.node-serie')
        .find('.row-upper .col-sm-6')
          .matchHeight()
          .end()
        .find('.row-lower .col-sm-6')
          .matchHeight({property: 'min-height'});
      
      // Truncate texts in Technical informations
      var minimized_elements = $('.group-technical-informations .field-items');
      
      minimized_elements.each(function(){
          $(this)
            .find('.field-item')
              .addClass('truncated')
              .end()
            .append('<a href="#" class="more">' + Drupal.t('Read more', {}, {'context': 'amphenol'}) + '</a><a href="#" class="less" style="display: none;">' + Drupal.t('Close', {}, {'context': 'amphenol'}) + '</a>');
          
          $.fn.matchHeight._update();
      });
      
      $('a.more', minimized_elements).click(function(event){
          event.preventDefault();
          
          $(this)
            .hide() // hide more link
            .prev() // .field-item
              .removeClass('truncated')
              .end()
            .next() // close link
              .show();
          
          $.fn.matchHeight._update();
      });
      
      $('a.less', minimized_elements).click(function(event){
          event.preventDefault();
          
          $(this)
            .hide() // hide close link
            .prev()
              .show() // show more link
              .prev() // .field-item
                .addClass('truncated');
          
          // Scroll to top of the field
          $('html,body').stop(true, true).animate({scrollTop: $(this).parents('.field').offset().top - 15});
          
          $.fn.matchHeight._update();
      });
      
      // Quicktabs on mobile
      enquire.register('(max-width: ' + (bp.medium - 1) + 'px)', {
        match: function() {
          $('a.quicktabs-tab').on('click.quicktabsMobile', function(){
            if ($(this).parent().hasClass('active')) {
              $(this)
                .parents('ul')
                  .eq(0)
                    .toggleClass('opened');
            }
          });
        },
        unmatch: function() {
          $('ul.quicktabs-tabs')
            .removeClass('opened')
            .find('a')
              .off('.quicktabsMobile');
        }
      });
      
      // Product selector : move facetapi filters into respective <th>
      if ($('.view-amphenol-products').length > 0) {
        $('.pane-facetapi').each(function(){
          var classList = $(this).find('form')[0].className.split(/\s+/);
          var fieldName = '';
          for (var i in classList) {
            if (classList[i].substring(0, 15) == "facetapi-facet-") {
              fieldName = classList[i].substring(15);
            }
          }
          
          if (fieldName == 'title') {
            fieldName += '-field';
          }
          
          var tableHead = $('.view-amphenol-products').find('th.views-field-' + fieldName);
          if (tableHead.length > 0) {
            $(this).find('form').appendTo(tableHead);
          }
        });
      }
    }
    
    // Block related product > Uniform height
    //var $blockRelatedProducts = $('#block-views-related-products-block');
    var $blockRelatedProducts = $('.field-name-field-manual-releated-products');
    if ( $blockRelatedProducts.length > 0 ) {
      $blockRelatedProducts
        .find('.field-name-title-field')
          .matchHeight()
          .end()
        .find('.field-name-body')
          .matchHeight();
    }
    
    
    
    // ==============================================
    // TAXONOMY FAQ CATEGORIE
    // ==============================================
    
    //var $viewFaqCategoriesPage = $('.view-faq-categories.view-display-id-page');
    var $viewFaqCategoriesPage = $('.view-amphenol-faq.view-display-id-page');
    if ($viewFaqCategoriesPage.length > 0) {
      $viewFaqCategoriesPage.find('.views-field-name a > div').matchHeight();
    }
    
    
    // View resource > Uniform height
    if ( $('.view-resources').length > 0 ) {
      $('.node-ressource')
        .find('.node-content')
          .matchHeight();
    }
    
    // View resource > Video
    $('.video-link').colorbox({
      iframe: true,
      className: 'cbox-video',
      width: 1280,
      height: 720,
      maxWidth: '100%',
      maxHeight: '90%'
    });
    
    
    
    // ==============================================
    // NODE TECHNOLOGIE
    // ==============================================
    
    if (body.hasClass('node-type-technologie')) {
      
      var fieldSeries = $('.field-name-field-series');
      if (fieldSeries.length) {
        // Slider
        var fieldSeriesSlider = fieldSeries.find('> .wrap > .field-items');
        var fieldSeriesSliderItems = fieldSeriesSlider.find('> .field-item');
        var fieldSeriesSliderParams = {
          items: 1,
          margin: 30,
          center: true,
          stagePadding: 50,
          loop: true,
          nav: false,
          dots: false,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 2,
              center: false
            },
            771: {
              items: 1,
              center: false,
              margin: 0,
              stagePadding: 0,
              loop: false,
              nav: true
            }
          },
          onInitialized: function(event) {
            var params = { byRow: true };
            if (fieldSeriesSlider.find('.field-items-group').length === 0) {
              // Problem with clones of owl carousel
              params.byRow = false;
            }
            matchHeightNodeSerieTeaser(params);
          },
          /*onChanged: function(event) {
            $.fn.matchHeight._update();
          },*/
          onResized: function(event) {
            // Needed here too because of a bug of matchHeight
            var params = { byRow: true };
            if (fieldSeriesSlider.find('.field-items-group').length === 0) {
              params.byRow = false;
            }
            matchHeightNodeSerieTeaser(params);
          }
        };
        
        enquire.register('(max-width: ' + (bp.medium - 1) + 'px)', {
            match: function() {
              destroyOwlCarousel(fieldSeriesSlider);
              
              // Ungroup items
              fieldSeriesSlider
                .find('.field-items-group')
                  .contents()
                    .unwrap();
              
              handleOwlCarousel(fieldSeriesSlider, fieldSeriesSliderParams);
            }
        }, true).register('(min-width: ' + bp.medium + 'px)', {
            match: function() {
              destroyOwlCarousel(fieldSeriesSlider);
              
              // Group items by six
              var nbItemsGroup = Math.ceil(fieldSeriesSliderItems.length % 6);
              for (var i = 0 ; i <= nbItemsGroup ; i++){
                fieldSeriesSliderItems
                  .slice(i * 6, i * 6 + 6)
                  .wrapAll('<div class="field-items-group">');
              }
              
              handleOwlCarousel(fieldSeriesSlider, fieldSeriesSliderParams);
            }
        }, true);
        
      }
    }
    
    
    
    // ==============================================
    // LISTING NEWS
    // ==============================================
    
    if ($('.view-amphenol-news-events').length > 0) {
      
      // Uniform height on node-news-event
      $('.node-news-event.node-teaser')
        .find('.node-content')
          .matchHeight();
    }
    
    
    
    // ==============================================
    // PCN
    // ==============================================
    
    /*if ($('.view-pcn').length > 0) {
      $('.view-pcn').find('a[href*="modal_forms"]').on('click', function(e){
        e.preventDefault();
        $.colorbox({
          iframe: true,
          href: $(this).attr('href'),
          className: 'cbox-pcn',
          width: '100%',
          height: '100%',
          maxWidth: 560,
          maxHeight: 610,
        });
      });
    }*/
    
    
    // ==============================================
    // NODE OFFRE EMPLOI
    // ==============================================
    
    if ( $('.node-offre-emploi').length ) {
      $('.form-link').click(function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 400);
      });
    }
    
    
    
    // ==============================================
    // RESOURCES > GALLERY IMAGES
    // ==============================================
    
    if ($('.view-images-gallery').length > 0) {
      var cboxImageParams = {
        className: 'cbox-image',
        width: w.width() < bp.small ? '100%' : '95%',
        height: w.width() < bp.small ? '100%' : '95%',
        current: "{current}/{total}"
      };
      $('.node-ressource.view-mode-ressource_image').find('.field-name-field-image a').colorbox(cboxImageParams);
    }
    
    
    
    // ==============================================
    // NODE PAGE
    // ==============================================
    
    if (body.hasClass('node-type-page')) {
      var $iframeInBody = $('.node-page').find('.field-name-body iframe:not([src*=youtube]):not([src*=netcomponents]):not([src*=google.com/maps])');
      if ($iframeInBody.length > 0){
        enquire.register('(max-width: ' + bp.medium + 'px)', {
          match: function() {
            // Hide iframe and show unavailable message
            $iframeInBody
              //.hide() // hidden by css
              .parent()
                .append('<div class="iframe-mobile-msg"><p>' + Drupal.t('Page unavailable on mobile devices.', {}, {context: 'amphenol'}) + '</p></div>');
          },
          unmatch: function() {
            $iframeInBody
              //.show()
              .next('.iframe-mobile-msg')
                .remove();
          }
        });
      }
    }
    
    
  });
  
  function matchHeightNodeSerieTeaser(params){
    if (params === undefined) params = {};
    $('.node-serie.node-teaser')
      .find('.field-name-title-field')
        .matchHeight({remove: true})
        .matchHeight(params)
        .end()
      .find('.field-name-body')
        .matchHeight({remove: true})
        .matchHeight(params);
  }
  
  function handleOwlCarousel(container, params) {
    destroyOwlCarousel(container);
    if (container.children().length > params.items) {
      container.addClass('owl-carousel').owlCarousel(params);
    }
  }

  function destroyOwlCarousel(owl) {
    if (owl !== undefined && owl !== null) {
      owl.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
      owl.find('.owl-stage-outer').children().unwrap();
    }
  }

})(jQuery, Drupal, this, this.document);
